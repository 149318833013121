import React, { useEffect, useState } from "react";
import { Animated } from "react-animated-css";
import { FormGroup, Input, Label } from "reactstrap";
import "./Registrarse.css";
import "jquery-confirm";
import styled from "styled-components";
import "animate.css";

const FormDatosSeguridad = ({
  pass1,
  clave1,
  setclave1,
  errors,
  pass2,
  clave2,
  setclave2,
  handlePassView,
  handleChange,
  handlePassView2,
  handleNotificarCorreo,
  handleSlideBack,
  handleSubmit,
  handleClickTerminos,
  viewTerms,
  size,
  animacion,
  setAnimacion,
  aceptarTerminos,
  setaceptarTerminos,
}) => {
  const [animateClass, setanimateClass] = useState(animacion);

  useEffect(() => {
    setTimeout(async () => {
      if (animacion !== "") {
        await setanimateClass("");
      }
    }, 600);
  }, []);

  return (
    <ContainerPrincipalFormSeguridad size={size} className="container">
      <div className={"row justify-content-md-center " + animateClass}>
        <Col1 className="col-4">
          <DivFormClave>
            <FormGroup className="pos-rel text-left border">
              <Label
                className={
                  errors.clave === ""
                    ? "label text-small op-text reg-label z-4"
                    : "text-danger text-small error-reg"
                }
                htmlFor="clave"
              >
                {errors.clave !== "" ? errors.clave : "Clave"}
              </Label>
              <div className="input-group">
                <Input
                  style={{ marginTop: "0.5em" }}
                  type="text"
                  autoComplete="off"
                  name="clave"
                  id="clave"
                  className="input reg-input text-pass"
                  maxLength="20"
                  value={clave1}
                  onChange={(e) => {
                    setclave1(e.target.value);
                    handleChange(e);
                  }}
                />
                <div
                  onClick={handlePassView}
                  className="pos-rel"
                  style={{ width: "15%" }}
                >
                  <Animated
                    style={{
                      position: "absolute",
                      top: "22%",
                      right: "30%",
                    }}
                    animationIn="fadeIn"
                    animationInDuration={200}
                    animationOutDuration={200}
                    animateOnMount={false}
                    isVisible={!pass1}
                  >
                    <span
                      data-width="20px"
                      className="iconify op-text"
                      data-icon="eva:eye-off-2-outline"
                      data-inline="false"
                    ></span>
                  </Animated>

                  <Animated
                    style={{
                      position: "absolute",
                      top: "22%",
                      right: "30%",
                    }}
                    animationIn="fadeIn"
                    animationInDuration={200}
                    animationOutDuration={200}
                    animateOnMount={false}
                    isVisible={pass1}
                  >
                    <span
                      data-width="20px"
                      className="iconify op-text"
                      data-icon="eva:eye-outline"
                      data-inline="false"
                    ></span>
                  </Animated>
                </div>
              </div>

              <span
                className="op-text"
                id="pasLabel"
                style={{
                  textTransform: "capitalize",
                  position: "absolute",
                  top: "38%",
                  left: "4%",
                  zIndex: "3",
                }}
              >
                {clave1 === "" ? "Contraseña" : ""}
              </span>
            </FormGroup>
          </DivFormClave>

          <DivFormConfirmarClave>
            <FormGroup className="pos-rel text-left border mb-2">
              <Label
                className={
                  errors.confirmaClave === ""
                    ? "label text-small op-text reg-label z-4"
                    : "text-danger text-small error-reg"
                }
                htmlFor="confirmaClave"
              >
                {errors.confirmaClave !== ""
                  ? errors.confirmaClave
                  : "Confirmar clave"}
              </Label>

              <div className="input-group">
                <Input
                  style={{ marginTop: "10px" }}
                  type="text"
                  autoComplete="off"
                  name="confirmaClave"
                  id="confirmaClave"
                  className="input reg-input text-pass"
                  maxLength="20"
                  value={clave2}
                  onChange={(e) => {
                    setclave2(e.target.value);
                    handleChange(e);
                  }}
                />
                <div
                  onClick={handlePassView2}
                  className="pos-rel"
                  style={{ width: "15%" }}
                >
                  <Animated
                    style={{
                      position: "absolute",
                      top: "22%",
                      right: "30%",
                    }}
                    animationIn="fadeIn"
                    animationInDuration={200}
                    animationOutDuration={200}
                    animateOnMount={false}
                    isVisible={!pass2}
                  >
                    <span
                      data-width="20px"
                      className="iconify op-text"
                      data-icon="eva:eye-off-2-outline"
                      data-inline="false"
                    ></span>
                  </Animated>

                  <Animated
                    style={{
                      position: "absolute",
                      top: "22%",
                      right: "30%",
                    }}
                    animationIn="fadeIn"
                    animationInDuration={200}
                    animationOutDuration={200}
                    animateOnMount={false}
                    isVisible={pass2}
                  >
                    <span
                      data-width="20px"
                      className="iconify op-text"
                      data-icon="eva:eye-outline"
                      data-inline="false"
                    ></span>
                  </Animated>
                </div>
              </div>

              <span
                className="op-text"
                id="pasLabel2"
                style={{
                  textTransform: "capitalize",
                  position: "absolute",
                  top: "38%",
                  left: "4%",
                  zIndex: "3",
                }}
              >
                {clave2 === "" ? "Confirma contraseña" : ""}
              </span>
            </FormGroup>
          </DivFormConfirmarClave>

          <DivTextoClave className="text-center text-small">
            La clave debe contener al menos 8 caracteres, una mayúscula, una
            minúscula y un número.
          </DivTextoClave>
        </Col1>
        {/*<FormGroup>
                        <div id="IngresoCaptcha" tabIndex="1000">	
                            {jsxCaptcha}
                        </div>		
                        </FormGroup>
                        <hr className="mb-0 w-90 mx-auto"/>*/}
        <Col2 className="col-4">
          <DivNotificaciones>
            <FormGroup className="pos-rel ">
              <div className="op-text text-left text-small noti-label">
                Recibir notificaciones por email
              </div>

              <div className="double acepto redondo">
                <Input
                  className="m-0"
                  type="checkbox"
                  name="aceptarNoti"
                  id="notificacionCorreo"
                  onClick={handleNotificarCorreo}
                />
                <label
                  htmlFor="notificacionCorreo"
                  style={{ top: "-2%", left: "-2%" }}
                ></label>
                <span className="mt-2">Acepto</span>
              </div>
            </FormGroup>
          </DivNotificaciones>

          <DivTerminosyCondiciones>
            <div className="mt-2" style={{ textAlign: "left" }}>
              <span className="op-text text-small ml-2">
                Términos y condiciones -{" "}
              </span>
              <span
                style={{ cursor: "pointer" }}
                className="text-info"
                data-toggle="modal"
                data-target="#terminosModal"
                onClick={viewTerms}
              >
                Ver
              </span>
            </div>

            <div
              className="pos-rel"
              style={{ marginTop: "-10px", marginLeft: "-13px" }}
            >
              <FormGroup className="mb-4 mt-4" check>
                <div className="double acepto redondo">
                  <Input
                    className="m-0 mr-1"
                    type="checkbox"
                    name="aceptarTerminos"
                    id="aceptarTerminos"
                    onClick={handleClickTerminos}
                  />
                  <label
                    htmlFor="aceptarTerminos"
                    style={{ top: "-20%", left: "-3%" }}
                  ></label>
                  <span className="ml-3">Acepto</span>
                </div>
              </FormGroup>
              <span
                className="text-danger text-small error-reg"
                style={{ top: "80%" }}
              >
                {errors.aceptarTerminos}
              </span>
            </div>
          </DivTerminosyCondiciones>
        </Col2>
        {/* /step-3 */}
      </div>

      <div className="row justify-content-between">
        <div className="col-4">
          <button
            id="backButtonStep3"
            style={{ position: "fixed", bottom: "10%", left: "10%" }}
            onClick={() => {
              setAnimacion("");
              setanimateClass("animate__animated animate__backOutRight");
              setTimeout(() => {
                handleSlideBack();
              }, 600);
            }}
            className="btn backBtn prevBtn"
            type="button"
          >
            <span>&#x2190;</span>
          </button>
        </div>
        <div className="col-4">
          <button
            style={{ position: "fixed", bottom: "10%", right: "10%" }}
            onClick={handleSubmit}
            className="btn nextBtn text-light btn-success"
            type="button"
          >
            Registrarse
          </button>
        </div>
      </div>

      <div className="pos-rel" id="btnGrupoRegistro"></div>
    </ContainerPrincipalFormSeguridad>
  );
};

export default FormDatosSeguridad;
const ContainerPrincipalFormSeguridad = styled.div`
  margin-top: ${(props) => (props.size >= 801 ? "10rem" : "5rem")};
`;

const DivFormClave = styled.div`
  max-width: 20rem;
`;

const DivFormConfirmarClave = styled.div`
  max-width: 20rem;
`;

const DivTextoClave = styled.div`
  max-width: 20rem;
  text-align: justify !important;
`;

const DivTerminosyCondiciones = styled.div`
  min-width: 20rem;
`;

const DivNotificaciones = styled.div`
  min-width: 20rem;
`;

const Col1 = styled.div`
  min-width: 20rem;
  margin-bottom: 2rem;
`;

const Col2 = styled.div``;
