import React, { useEffect, useState } from "react";
import { Animated } from "react-animated-css";
import NumberFormat from "react-number-format";
import styled from "styled-components";
import { estilosProv } from "../coloresProvincias";
import EfectoCargando, { DivCargando } from "../tools/EfectoCargando";

const ConsultaBilletera = (props) => {
  const [consultando, setconsultando] = useState("");
  const [fecHor, setfecHor] = useState(undefined);
  const [movimientos, setmovimientos] = useState([]);
  const [error, seterror] = useState(false);
  const [page, setPage] = useState(0);
  const sizePaginacion = 8;
  const codOrganizacion = process.env.REACT_APP_COD_ORGANIZACION;
  const colorFondo = estilosProv[codOrganizacion].fondo;

  /**
   * Llamadas a las funciones cuando se inicializa el componente
   */
  useEffect(() => {
    window.scrollTo(0, 0);

    props.logCurrentPage("billetera");

    props.refreshConsultaBilletera.laFuncion = refrescarConsulta;

    setconsultando("EJECUTANDO");

    props.actuVersionSiCorresponde(props);

    refrescarConsulta();
  }, []);

  /**
   * Se realiza una peticion fetch, en caso que la peticion sea exitosa se setearan los
   * @const consultando
   * @const fecHor
   * @const errores
   * @const movimientos
   * en caso que haya un error se llamara a la funcion @function mensajeErrorWS
   */
  const refrescarConsulta = () => {
    let url = process.env.REACT_APP_WS_GET_MOV_BILETTERA;

    let cerrarSesion = false;

    setconsultando("EJECUTANDO");

    fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: "Bearer " + props.estadoPlataforma.userToken,
      },
    })
      .then((respPromise) => {
        setconsultando("FIN");
        if (respPromise.status === process.env.REACT_APP_CODIGO_CERRO_SESION) {
          cerrarSesion = true;
        }
        return respPromise.json();
      })
      .then((json) => {
        if (json.status === "ok") {
          setconsultando("FIN");
          setfecHor(json.fecHor);
          setmovimientos(json.movimientos);
        } else if (json.status === "error") {
          setconsultando("FIN");
          if (cerrarSesion) {
            props.mensajeErrorWS(
              "Consulta movimientos Billetera",
              json.errores,
              cerrarSesion
            );
          } else {
            props.mensajeErrorWS(
              "Consulta movimientos Billetera",
              json.errores
            );
          }
        }
      })
      .catch((error) => {
        setconsultando("FIN");
        seterror(true);
        //	this.props.mensajeErrorGeneral();
      });
  };

  /**
   *
   * @returns {array} a partir de un @param {array} arr retorna un nuevo array que contiene los elementos desde @param index
   * hasta llegar a la cantidad de  @param size
   */
  const copiaArray = (arr, index, size) => {
    if (arr.length > 0) {
      let arrayResult = [];
      for (let i = index * size; arrayResult.length < size; i++) {
        if (arr[i] !== undefined) {
          arrayResult.push(arr[i]);
        } else {
          break;
        }
      }
      return arrayResult;
    }
  };

  /**
   *
   * @returns Componente que genera un tbody con los diferentes movimientos de la billetera del usuario
   */
  const Movimientosomponent = () => {
    let movimientosPaginados = copiaArray(movimientos, page, sizePaginacion);

    return movimientosPaginados.map((movi, index) => (
      <tbody>
        <tr key={index}>
          <td
            className="op-text"
            style={{ paddingBottom: "2px", fontSize: "17px" }}
            align="left"
          >
            {movi.fecHor.substring(0, 6) + movi.fecHor.substring(8, 10)} -{" "}
            {movi.fecHor.substring(11, 19)}
          </td>
        </tr>

        <tr>
          <td className="p-0 op-text" align="left" style={{ fontSize: "17px" }}>
            {movi.concepto.split("-", "1")}{" "}
          </td>
        </tr>
        <tr>
          <td className="p-0 m-bold" align="left" style={{ fontSize: "17px" }}>
            {movi.concepto.split("-", 2)[1]}
          </td>

          <td className="p-0" style={{ fontSize: "17px" }} align="right">
            <NumberFormat
              className={movi.importe <= 0 ? "negative" : "positive"}
              value={movi.importe}
              displayType={"text"}
              thousandSeparator={"."}
              decimalSeparator={","}
              prefix={" $ "}
              decimalScale={2}
              fixedDecimalScale={false}
              isNumericString={true}
            />
          </td>
        </tr>
        <hr style={{ width: "132%" }} />
      </tbody>
    ));
  };

  /**
   *
   * @returns Retorna un componente con los movimientos de la billetera del usuario paginados
   */
  const Paginacion = () => {
    let numerosPaginas = [];

    for (let i = 0; i < movimientos.length / sizePaginacion; i++) {
      numerosPaginas.push(
        <li className={page === i ? "page-item active" : "page-item "}>
          <SpanNumeros
            color={colorFondo}
            style={
              page === i
                ? { cursor: "pointer", backgroundColor: colorFondo }
                : { cursor: "pointer" }
            }
            className="page-link"
            onClick={() => {
              window.scrollTo(0, 0);
              setPage(i);
            }}
          >
            {i + 1}
          </SpanNumeros>
        </li>
      );
    }
    return (
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12">
            <table className="mx-auto mb-3 mov-table" style={{ width: "100%" }}>
              <Movimientosomponent />{" "}
            </table>
          </div>
          <DivPaginacion className="col-6 align-self-center">
            <nav
              className="nav justify-content-center"
              aria-label="..."
              style={{ marginBottom: "15vh" }}
            >
              <ul className="pagination">
                <li className={page === 0 ? "page-item disabled" : "page-item"}>
                  <SpanNumeros
                    color={colorFondo}
                    style={{ cursor: "pointer" }}
                    className="page-link"
                    onClick={() => {
                      if (page >= 1) {
                        window.scrollTo(0, 0);
                        setPage(page - 1);
                      }
                    }}
                  >
                    {"<"}
                  </SpanNumeros>
                </li>

                <li className="page-item active">
                  <SpanNumeros
                    color={colorFondo}
                    style={{ cursor: "pointer", backgroundColor: colorFondo }}
                    className="page-link"
                  >
                    {page}
                  </SpanNumeros>
                </li>

                <li
                  className={
                    page < movimientos.length / sizePaginacion - 1
                      ? "page-item"
                      : "page-item disabled"
                  }
                >
                  <SpanNumeros
                    color={colorFondo}
                    style={{ cursor: "pointer" }}
                    className="page-link"
                    onClick={() => {
                      if (page < movimientos.length / sizePaginacion - 1) {
                        window.scrollTo(0, 0);
                        setPage(page + 1);
                      }
                    }}
                  >
                    {">"}
                  </SpanNumeros>
                </li>
              </ul>
            </nav>
          </DivPaginacion>
        </div>
      </div>
    );
  };
  return (
    <DivGeneral hidden={props.estadoPlataforma.estado !== "PLATAFORMA"}>
      <Animated animationIn="fadeIn" className="pageFlow">
        <div>
          <Animated
            animationIn="flipInX"
            animationInDelay={100}
            style={{ marginTop: "5vh" }}
          >
            <div className="text-center">
              <h3
                onClick={() => {
                  refrescarConsulta();
                  props.refreshSaldo();
                }}
              >
                <img
                  src={
                    require("../img/icons/BILLETERA_ICON_ORG_" +
                      process.env.REACT_APP_COD_ORGANIZACION +
                      ".png").default
                  }
                  alt=""
                />

                <div className="ml-2 mt-4 sm-6">
                  <div>Mi Billetera</div>
                  <h6 className="text-small">Ultimos Movimientos</h6>
                </div>
              </h3>
            </div>
          </Animated>

          {consultando === "EJECUTANDO" && (
            <DivCargando className="container">
              <EfectoCargando />
            </DivCargando>
          )}

          {error === false && (
            <div>
              {consultando === "FIN" && fecHor != null && fecHor.length > 0 && (
                <div id="billetera-mov">
                  {movimientos.length === 0 && (
                    <div className="text-center mt-4 op-text">
                      <b>
                        No se registraron movimientos <br></br>en los ultimos
                        dias
                      </b>
                    </div>
                  )}

                  <div>{movimientos.length > 0 ? <Paginacion /> : <></>}</div>
                </div>
              )}
            </div>
          )}

          {error === true && (
            <DivNoDisponible>
              <br></br>
              <p>La consulta de billetera no se encuentra disponible.</p>
              <p>Favor reintente más tarde.</p>
            </DivNoDisponible>
          )}
        </div>
      </Animated>
    </DivGeneral>
  );
};

export default ConsultaBilletera;

export const DivGeneral = styled.div`
  overflow-x: scroll;
  height: 84vh;
`;

const DivPaginacion = styled.div`
  margin-bottom: 5rem;
  margin-top: 2vh;
`;

const SpanNumeros = styled.span`
  color: ${(props) => props.color};
  border-color: white !important;
  &:hover {
    color: ${(props) => props.color}}
  }
`;
const DivNoDisponible = styled.div`
  text-align: center;
`;
