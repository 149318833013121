import React, { useState, useEffect } from "react";
import { Animated } from "react-animated-css";
import { Link } from "react-router-dom";
import styled from "styled-components";
import $ from "jquery";

const BottomNavMobile = (props) => {
  const [visible, setvisible] = useState(false);
  const testAnim = () => {
    let b = document.getElementById("newGameModal");
    if (b !== null) {
      if (b.className.includes("modal fade show")) {
        $(".ripple").click();
      }
    }
    props.volverAPlataforma();
  };

  useEffect(() => {
    let b = document.getElementById("newGameModal");
    if (b !== null) {
      if (b.className.includes("modal fade show")) {
        setvisible(!visible);
      }
    }
  }, []);

  return (
    <>
      <BotonVerMenu
        bottom="3.5rem"
        type="button"
        className="btn btn-light"
        hidden={visible}
        onClick={() => setvisible(!visible)}
      >
        <span
          className="iconify help-arrow"
          data-icon="dashicons:arrow-down-alt2"
          data-inline="false"
          data-width="25px"
        ></span>
      </BotonVerMenu>
      <BotonVerMenu
        bottom="0rem"
        type="button"
        className="btn btn-light"
        hidden={!visible}
        onClick={() => setvisible(!visible)}
      >
        <span
          className="iconify help-arrow"
          data-icon="dashicons:arrow-up-alt2"
          data-inline="false"
          data-width="25px"
        ></span>
      </BotonVerMenu>

      <DivMenuRapido hidden={visible}>
        {props.logueado && (
          <div
            className="row mx-auto p-1 text-center"
            style={{ backgroundColor: "#FFF", width: "100%" }}
          >
            {" "}
            <Link
              className="col sm-2 pr-0 pl-0 inicioIphon"
              exact={"true"}
              activeclassname="is-active"
              to="/home"
            >
              <div
                onClick={() => {
                  testAnim();
                }}
              >
                <span
                  className="iconify q-icon home-icon"
                  data-icon="bytesize:home"
                  data-inline="false"
                  data-width="23px"
                ></span>
                <div className="text-small" style={{ marginTop: "-1px" }}>
                  <span className="home-text q-icon">Inicio</span>
                </div>
              </div>
            </Link>
            <Link
              to="/consultaBilletera"
              onClick={() => {
                testAnim();
              }}
              className="col pr-0 pl-0 sm-2 billeIphon"
            >
              <div>
                <span
                  className="iconify billetera-icon q-icon"
                  data-icon="ion:wallet-outline"
                  data-inline="false"
                  data-width="25px"
                ></span>
                <div className="text-small" style={{ marginTop: "-3px" }}>
                  <span className="billetera-text q-icon">Billetera</span>
                </div>
              </div>
            </Link>
            <div
              className="ripple"
              data-toggle="modal"
              data-target="#newGameModal"
              hidden={props.estado !== "PLATAFORMA"}
              style={{ zIndex: "3", padding: "0 1em", minWidth: "3em" }}
            >
              <div
                className={
                  "jugar-icon-cont jugar-btn-prov-" +
                  process.env.REACT_APP_COD_ORGANIZACION
                }
              >
                <span
                  style={{ marginTop: "5px" }}
                  className="iconify"
                  data-icon="fluent:lottery-24-regular"
                  data-inline="false"
                  data-width="40px"
                ></span>
              </div>
            </div>
            <Link className="col pr-0 pl-0 sm-2" to="/identificadorQRCliente">
              <div
                onClick={() => {
                  testAnim();
                }}
              >
                <span
                  className="iconify retirar-icon q-icon"
                  data-icon="uil:money-withdrawal"
                  data-inline="false"
                  data-width="25px"
                ></span>
                <div className="text-small" style={{ marginTop: "-2px" }}>
                  <span className="retirar-text q-icon">Retirar</span>
                </div>
              </div>
            </Link>
            <Link
              className="col pr-0 pl-0 sm-2 userIphon5"
              to="/misDatosPersonales"
            >
              <div
                onClick={() => {
                  testAnim();
                }}
              >
                <span
                  className="iconify user-icon q-icon"
                  data-icon="bi:person"
                  data-inline="false"
                  data-flip="horizontal"
                  data-width="25px"
                ></span>
                <div className="text-small" style={{ marginTop: "-3px" }}>
                  <span className="user-text q-icon">Usuario</span>
                </div>
              </div>{" "}
            </Link>
          </div>
        )}
      </DivMenuRapido>
    </>
  );
};

export default BottomNavMobile;

const BotonVerMenu = styled.button`
  bottom: ${(props) => props.bottom};
  position: fixed;
  z-index: 1;
  background-color: rgb(227, 229, 231);
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
  left: 0;
`;

const DivMenuRapido = styled.div`
  position: fixed;
  width: 100%;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  box-shadow: 0px 0 10px rgb(146 146 146 / 80%);
`;
