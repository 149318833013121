import React, { useEffect, useState } from "react";
import { FormGroup, Input, Label } from "reactstrap";
import OptionsLocalidades from "./tools/OptionsLocalidades";
import OptionsProvincias from "./tools/OptionsProvincias";
import "jquery-confirm";
import styled from "styled-components";
import "animate.css";
import { SpanDangerDinamico } from "./FormDatospersonales";

const FormDatosContacto = ({
  provincias,
  localidades,
  localidad,
  setlocalidad,
  direccion,
  setdireccion,
  correo,
  setcorreo,
  errors,
  telefono,
  settelefono,
  agenciaSeleccionada,
  handleChange,
  seleccionaTuAgencia,
  handleSlideBack,
  handleSlide,
  size,
  animacion,
  setAnimacion,
}) => {
  const [animateClass, setanimateClass] = useState(animacion);

  useEffect(() => {
    setTimeout(async () => {
      if (animacion !== "") {
        await setanimateClass("");
      }
    }, 600);
  }, []);

  const FornProvincias = (SinUsar) => (
    <div className="pos-rel" style={{ opacity: "0" }}>
      <FormGroup className="p-1 border pos-rel mb-0">
        <Label
          className="label reg-label text-small op-text"
          htmlFor="provincia"
        >
          Provincia
        </Label>
        <Input
          style={{ marginTop: "5px" }}
          type="select"
          name="provincia"
          id="provincia"
          className="input reg-input"
          placeholder="Provincia"
          onChange={handleChange}
        >
          <OptionsProvincias provincias={provincias} />
        </Input>
      </FormGroup>
      <span className="text-danger text-small error-reg">
        {errors.provincia}
      </span>
    </div>
  );

  return (
    <FormDatosContactos size={size} className="container">
      <RowFormularios
        className={"row justify-content-md-center " + animateClass}
      >
        <Col1 className="col-4">
          <FormLocalidades>
            <FormGroup className="p-1 border pos-rel">
              <SpanDangerDinamico
                error={errors.localidad}
                className="label text-small op-text reg-label"
                htmlFor="localidad"
              >
                {errors.localidad ? errors.localidad : "Localidad"}{" "}
              </SpanDangerDinamico>

              <Input
                autoComplete="nope"
                style={{ marginTop: "5px" }}
                type="select"
                name="localidad"
                id="localidad"
                className="input reg-input"
                placeholder="Localidad"
                value={localidad}
                onChange={(e) => {
                  setlocalidad(e.target.value);
                  handleChange(e);
                }}
              >
                <option key="option_default" hidden selected value={undefined}>
                  Seleccione una Localidad
                </option>
                <OptionsLocalidades localidades={localidades} />
              </Input>
            </FormGroup>
          </FormLocalidades>

          <FormDireccion>
            <FormGroup className="p-1 border pos-rel">
              <SpanDangerDinamico
                error={errors.direccion}
                className="label text-small op-text reg-label"
                htmlFor="direccion"
              >
                {errors.direccion ? errors.direccion : "Dirección"}{" "}
              </SpanDangerDinamico>
              <Input
                autoComplete="none"
                type="text"
                name="direccion"
                id="direccion"
                className="input reg-input"
                style={{
                  textTransform: "uppercase",
                  marginTop: "5px",
                }}
                maxLength="100"
                placeholder="Dirección"
                value={direccion}
                onChange={(e) => {
                  handleChange(e);
                  setdireccion(e.target.value);
                }}
              />
            </FormGroup>
          </FormDireccion>

          <FormCorreo>
            <FormGroup className="p-1 border pos-rel">
              <SpanDangerDinamico
                error={errors.correo}
                className="label text-small op-text reg-label"
                htmlFor="correo"
              >
                {errors.correo ? errors.correo : "Correo electrónico"}{" "}
              </SpanDangerDinamico>
              <Input
                autoComplete="none"
                style={{
                  textTransform: "lowercase",
                  marginTop: "5px",
                }}
                type="email"
                className="input reg-input"
                name="correo"
                id="correo"
                maxLength="100"
                placeholder="Email*"
                value={correo}
                onChange={(e) => {
                  handleChange(e);
                  setcorreo(e.target.value);
                }}
              />
            </FormGroup>
          </FormCorreo>
        </Col1>
        <div className="col-4">
          <FormTelefono>
            <FormGroup className="mb-4 p-1 border pos-rel">
              <SpanDangerDinamico
                error={errors.telefono}
                className="label text-small op-text reg-label"
                htmlFor="telefono"
              >
                {errors.telefono ? errors.telefono : "Teléfono"}{" "}
              </SpanDangerDinamico>

              <Input
                autoComplete="off"
                value={telefono}
                type="number"
                name="telefono"
                id="telefono"
                className="input reg-input mt-1"
                maxLength="20"
                placeholder="Teléfono"
                onChange={(e) => {
                  handleChange(e);
                  settelefono(e.target.value);
                }}
              />
            </FormGroup>
          </FormTelefono>

          <FormAgencia>
            <FormGroup className="select-agencia-cont">
              {/*-----------------------------MODAL AGENCIAS---------------------------*/}
              <div
                id="botonSeleccionarAgencia"
                className="text-left border p-2"
                tabIndex="-1"
              >
                <span className="ml-2 text-small op-text">
                  Tu agencia amiga
                </span>

                {agenciaSeleccionada !== null && (
                  <div className="ml-2">
                    <span className="m-bold  text-small">
                      Agencia n°{agenciaSeleccionada.descAgencia}{" "}
                    </span>
                    -
                    <span className="op-text text-small">
                      {" "}
                      {agenciaSeleccionada.direccion} -{" "}
                      {agenciaSeleccionada.localidad}
                    </span>
                  </div>
                )}

                <br />
                <button
                  type="button"
                  id="seleccionarAgencia"
                  onClick={seleccionaTuAgencia}
                  className="btn btn-secondary text-left p-2 agencia mx-auto"
                  data-toggle="modal"
                  data-target="#agenciaModal"
                >
                  {agenciaSeleccionada === null
                    ? "Seleccionar agencia"
                    : "Modificar agencia"}

                  <span
                    className="iconify"
                    data-icon="fe:arrow-right"
                    data-inline="false"
                    data-width="25px"
                  ></span>
                </button>
              </div>
            </FormGroup>
            <SpanDangerAgencia className="text-danger text-small error-reg">
              {errors.agencia}
            </SpanDangerAgencia>
          </FormAgencia>
        </div>
        {/* <button onClick={handleSlideBack} className="btn backBtn prevBtn" type="button" ><span>&#x2190;</span></button> */}
      </RowFormularios>
      <div className="row justify-content-between">
        <div className="col-4">
          <BotonBack
            id="backButton"
            onClick={() => {
              setAnimacion("");
              setanimateClass("animate__animated animate__backOutRight");
              setTimeout(() => {
                handleSlideBack();
              }, 600);
            }}
            className="btn backBtn prevBtn"
            type="button"
          >
            <span>&#x2190;</span>
          </BotonBack>
        </div>
        <ColBotonSiguiente className="col-4">
          <BotonSiguiente
            id="nextbutton"
            onClick={() => {
              setAnimacion("");
              setanimateClass("animate__animated animate__backOutLeft");
              setTimeout(() => {
                handleSlide();
              }, 600);
            }}
            className={
              "btn nextBtn bg-prov-" + process.env.REACT_APP_COD_ORGANIZACION
            }
            type="button"
          >
            Siguiente
          </BotonSiguiente>
        </ColBotonSiguiente>
      </div>{" "}
      {/* /step-2 */}
    </FormDatosContactos>
  );
};
export default FormDatosContacto;

const FormDatosContactos = styled.div`
  margin-top: ${(props) => (props.size >= 801 ? "10rem" : "5rem")};
`;

const Col1 = styled.div`
  min-width: 20rem;
`;

const ColBotonSiguiente = styled.div``;
const RowFormularios = styled.div`
  min-height: 17rem;
`;

const FormLocalidades = styled.div``;

const FormDireccion = styled.div``;

const FormCorreo = styled.div``;

const FormTelefono = styled.div`
  min-width: 20rem;
`;

const FormAgencia = styled.div`
  min-width: 20rem;
`;

export const BotonSiguiente = styled.button`
  position: fixed;
  bottom: 10%;
  right: 10%;
`;

export const BotonBack = styled.button`
  position: fixed;
  bottom: 10%;
  left: 10%;
`;

const SpanDangerAgencia = styled.span`
  top: 126px;
  text-align: left;
  left: 24px;
  white-space: nowrap;
  color: red !important;
`;

const SpanDangerLocalidad = styled.span`
  top: 126px;
  text-align: left;
  left: 24px;
`;
