import React, { useState } from "react";
import { Animated } from "react-animated-css";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { estilosProv } from "../../coloresProvincias";
import $ from "jquery";

const LlamadorJuego = ({
  enableJuego,
  clickJuego,
  cantCuponesJuego,
  logoJuego,
  nombreJuego,
  promocion,
  estadoPlataforma,
  urljuego,
  propSide,
  tieneCuil,
  size,
}) => {
  const codOrg = process.env.REACT_APP_COD_ORGANIZACION;
  const fondoLlamador = estilosProv[codOrg].fondoLlamador;
  const fondo = estilosProv[codOrg].fondo;
  const gradiente1Llamador = estilosProv[codOrg].gradiente1Llamador;
  const gradiente2Llamador = estilosProv[codOrg].gradiente2Llamador;

  const testAnim = () => {
    let b = document.getElementById("newGameModal");
    if (b !== null) {
      if (b.className.includes("modal fade show")) {
        $(".ripple").click();
      }
    }
  };

  const linkAJuego = () => {
    if (
      [
        "quinielatradicional",
        "quinielainstantanea",
        "tombola",
        "quiniexpress",
        "tomboexpress",
      ].includes(urljuego.toLowerCase())
    ) {
      return "/juego/" + urljuego;
    }
    if (
      ["patagoniaminibingo", "patagoniatelebingo", "pozodelaquiniela"].includes(
        urljuego
      )
    ) {
      if (tieneCuil) {
        return "/juego/" + urljuego;
      }
    }
  };

  return (
    enableJuego === "true" &&
    (estadoPlataforma.acceptedTyc === true ||
      estadoPlataforma.acceptedTyc === null ||
      estadoPlataforma.acceptedTyc === undefined) &&
    (estadoPlataforma.autoexcluidoHasta === undefined ||
      estadoPlataforma.autoexcluidoHasta === null) && (
      <DivIconoLlamadorJuego
        className={propSide === undefined ? "col" : propSide}
      >
        <Animated
          animationIn="flipInY"
          style={{ cursor: "pointer" }}
          animationInDuration={1200}
        >
          <Animated
            animationIn="flipInY"
            animationInDuration={1200}
            isVisible={true}
          >
            <ContainerJuegos
              className="container"
              onClick={() => {
                testAnim();
                if (urljuego === "patagoniaminibingo") {
                  clickJuego("20");
                } else if (urljuego === "patagoniatelebingo") {
                  clickJuego("21");
                } else {
                  clickJuego();
                }
                let b = document.getElementById("newGameModalDesktop");
                if (b !== null) {
                  if (b.className.includes("modal fade show")) {
                    $("#BtnNewGameDesktop123").click();
                  }
                }
              }}
            >
              <Link to={linkAJuego}>
                <div className="row justify-content-center text-center">
                  <FondoLogo
                    size={size}
                    aplicarGradiente={false}
                    gradiente1Llamador={
                      urljuego.toLowerCase() === "tombola"
                        ? "#f58634"
                        : urljuego.toLowerCase() === "tomboexpress"
                        ? "#ed3054"
                        : gradiente1Llamador
                    }
                    gradiente2Llamador={gradiente2Llamador}
                    colorFondo={
                      urljuego.toLowerCase() === "tombola"
                        ? "#f58634"
                        : urljuego.toLowerCase() === "tomboexpress"
                        ? "#ed3054"
                        : fondoLlamador
                    }
                    className="col align-self-center"
                  >
                    {cantCuponesJuego > 0 ? (
                      <BadgeNotificacionesJuego className="position-absolute top-0 start-100 translate-middle border border-light rounded-circle">
                        {cantCuponesJuego}
                      </BadgeNotificacionesJuego>
                    ) : (
                      <></>
                    )}
                    <LogoJuego size={size} src={logoJuego} alt="img" />
                  </FondoLogo>
                  <div className="col-13">
                    <NombreJuego>{nombreJuego}</NombreJuego>
                  </div>
                  <SpanPromociones size={size}>
                    {promocion !== undefined ? promocion : <></>}
                  </SpanPromociones>
                </div>
              </Link>
            </ContainerJuegos>
          </Animated>
        </Animated>
      </DivIconoLlamadorJuego>
    )
  );
};

export default LlamadorJuego;

const LogoJuego = styled.img`
  width: 10vh;
  margin-top: ${(props) => (props.size <= 991 ? "2vh" : "4.5vh")};
`;
const BadgeNotificacionesJuego = styled.span`
  background-color: #5a83bd;
  border: 2px solid white;
  border-radius: 50%;
  color: white;
  z-index: 3;
  width: 4vh;
  height: 4vh;
  text-align: center;
  padding-top: 0vh;
  left: 0px;
  font-size: 2vh;
`;

const NombreJuego = styled.div`
  margin-top: 4vh;
  text-align: center;
`;

const FondoLogo = styled.div`
  background: radial-gradient(
    circle at 65% 15%,
    ${(props) => (props.aplicarGradiente ? "white" : props.colorFondo)} 1px,
    ${(props) =>
        props.aplicarGradiente ? props.gradiente1Llamador : props.colorFondo}
      3%,
    ${(props) => (props.aplicarGradiente ? props.colorFondo : props.colorFondo)}
      60%,
    ${(props) =>
        props.aplicarGradiente ? props.gradiente2Llamador : props.colorFondo}
      100%
  );
  border-radius: 50%;
  height: ${(props) => (props.size <= 991 ? "15vh" : "20vh")};
  max-width: ${(props) => (props.size <= 991 ? "16vh" : "20vh")};
  ${(props) =>
    props.aplicarGradiente ? "box-shadow: 8px -5px 13px 4px #b0b7b3" : ""}
`;

const ContainerJuegos = styled.div`
  min-width: 15vh;
  margin-top: 8vh;
`;

const SpanPromociones = styled.span`
  background-color: #01c160;
  color: #f0f0f0;
  border-radius: 1em;
  position: absolute;
  top: ${(props) => (props.size <= 991 ? "13.5vh" : "17.5vh")};
  min-width: 8rem;
  text-align: center;
`;
const DivIconoLlamadorJuego = styled.div`
  min-width: 16vh;
`;
