import React from "react";
import $ from "jquery";
import "jquery-mask-plugin/dist/jquery.mask.min.js";
import { Animated } from "react-animated-css";
import { withRouter } from "react-router";
import ReactHtmlParser from "react-html-parser";
import CarrouselPublicidad from "../carrouselPublicidad";
import Calificar from "../calificar";
import { useState } from "react";
import { useEffect } from "react";
import styled from "styled-components";
import EfectoCargando, { DivCargando } from "../tools/EfectoCargando";
import ModalAvisosGenerales from "../components/ModalAvisosGenerales";
import LlamadorJuego from "../tools/home/llamadorJuego";

const Home = (props) => {
  const [cantCuponesJuego0, setcantCuponesJuego0] = useState("");
  const [cantCuponesJuego20, setcantCuponesJuego20] = useState("");
  const [cantCuponesJuego21, setcantCuponesJuego21] = useState("");
  const [cantCuponesJuego6, setcantCuponesJuego6] = useState("");
  const [logoJuego0, setlogoJuego0] = useState(undefined);
  const [logoJuego1, setlogoJuego1] = useState(undefined);
  const [logoJuego2, setlogoJuego2] = useState(undefined);
  const [logoJuego3, setlogoJuego3] = useState(undefined);
  const [logoJuego4, setlogoJuego4] = useState(undefined);
  const [latitud, setlatitud] = useState(undefined);
  const [longitud, setlongitud] = useState(undefined);
  const [avisosGenerales, setavisosGenerales] = useState({});
  const [jsonPromociones, setjsonPromociones] = useState(undefined);
  const [size, setSize] = useState(window.innerWidth);
  const [cargando, setCargando] = useState(true);
  const [userCuil, setuserCuil] = useState(undefined);
  const codOrg = process.env.REACT_APP_COD_ORGANIZACION;

  /**
   * Observador de tamaño de la ventana: Cuando la ventana cambia de tamaño se actualiza el state @size
   */
  useEffect(() => {
    const updateWindowDimensions = () => {
      const newWidth = window.innerWidth;
      setSize(newWidth);
    };

    window.addEventListener("resize", updateWindowDimensions);

    // return () => window.removeEventListener("resize", updateWindowDimensions);
  }, [size]);

  /**
   * Se realiza una petision fetch para traer los datos del usuario, en caso que la peticion
   * se realice correctamente se seteara el cuil del usuario en @const userCuil. en el caso
   * que haya error se lanzara la funcion @function mensajeErrorWS
   */
  const traerMisDatosPersonales = () => {
    window.scrollTo(0, 0);
    if (window.location.pathname === "/plataforma/home") {
      props.volverAPlataforma();
    }

    let url = process.env.REACT_APP_WS_MIS_DATOS_PERSONALES;

    let cerrarSesion = false;

    fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: "Bearer " + props.estadoPlataforma.userToken,
      },
    })
      .then((respPromise) => {
        if (respPromise.status === process.env.REACT_APP_CODIGO_CERRO_SESION) {
          cerrarSesion = true;
        }
        return respPromise.json();
      })
      .then((json) => {
        if (json.status === "ok") {
          setuserCuil(json.cuil);
          if (json.permiteCambioAg !== undefined) {
          }
        } else if (json.status === "error") {
          if (cerrarSesion) {
            props.mensajeErrorWS(
              "Consulta datos personales",
              json.errores,
              cerrarSesion
            );
          } else {
            props.mensajeErrorWS("Consulta datos personales", json.errores);
          }
        }
      })
      .catch((error) => {
        props.mensajeErrorGeneral();
      });
  };

  /**
   * Se realiza un pedido fetch para traer las posibles promociones que hayan para los diferentes juegos
   */
  const traerAvisosGenerales = () => {
    let url = process.env.REACT_APP_WS_LEER_AVISOS_GENERALES;
    fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: "Bearer " + props.estadoPlataforma.userToken,
      },
    })
      .then((respPromise) => {
        if (respPromise.status === process.env.REACT_APP_CODIGO_CERRO_SESION) {
        }
        return respPromise.json();
      })
      .then((json) => {
        setavisosGenerales(json.avisoItemsDTO);
      })
      .catch((error) => {
        props.mensajeErrorGeneral();
      });
  };

  /**
   * Se obtiene la @const latitud y @const longitud :
   * Por el momento se encuentra desactivado hasta que se aplique Geolocalizacion
   */
  useEffect(() => {
    if (false) {
      navigator.geolocation.getCurrentPosition(
        function (position) {
          setlatitud(position.coords.latitude.toString());
          setlongitud(position.coords.longitude.toString());
        },
        function (error) {
          console.error("Error Code = " + error.code + " - " + error.message);
        }
      );
    }
  }, []);

  /**
   * Observador que verifica que cuando haya un cambio en  @const latitud o @const longitud
   * y estos dejen de ser undefined se llame a la @function requiereGeolocalizacion
   */
  useEffect(() => {
    if (latitud !== undefined && longitud !== undefined && false) {
      requiereGeolocalizacion();
    }
  }, [latitud, longitud]);

  /**
   * Esta funcion genera un seteo inicial del componente, solo se ejecuta cuando el componente
   * se inicializa
   */
  useEffect(() => {
    props.logCurrentPage("home");
    cargarImgDinamica(
      "LOGO_JUEGO_0",
      "LOGO_JUEGO_1",
      "LOGO_JUEGO_20",
      "LOGO_JUEGO_21",
      "LOGO_JUEGO_6"
    );
    props.actuVersionSiCorresponde(props);

    traerMisDatosPersonales();

    traerAvisosGenerales();

    if (props.estadoPlataforma.logueado) {
      getDatosInicialesJuego("0");
      getDatosInicialesJuego("20");
      getDatosInicialesJuego("21");
      getDatosInicialesJuego("6");
    }

    if (codOrg === "42" || codOrg === "12") {
    }

    if (
      props.estadoPlataforma.nps !== undefined &&
      props.estadoPlataforma.nps !== null
    ) {
      if (props.estadoPlataforma.nps.sugerirNPS) {
        $("#rateModal").modal("show");
      }
    }

    if (props.estadoPlataforma.acceptedTyc === false) {
      $("#tycModal").modal("show");
    }
    setTimeout(setCargando(false), 6000);
  }, []);

  useEffect(() => {
    if (
      userCuil !== undefined &&
      process.env.REACT_APP_ENABLE_REQUIERE_CUIL == "true"
    ) {
      clickIngresenCuil();
    }
  }, [userCuil]);

  /**
   * Se realiza una peticion fetch con las  @const latitud y @const longitud para
   * ver si se encuentra dentro de la localizacion permitida
   */
  const requiereGeolocalizacion = () => {
    let url = process.env.REACT_APP_WS_DATOS_REQUIERE_GEO;

    fetch(
      `${url}?lat=${latitud}&lon=${longitud}&codigoOrganizacion=${codOrg}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: "Bearer " + props.estadoPlataforma.userToken,
        },
      }
    )
      .then((respPromise) => {
        console.log(respPromise);
      })
      .catch((error) => {
        console.error(error.message);
      });
  };

  /**
   * Se setean:
   * @param {*} nombre0 imagen tombola en @const logoJuego0
   * @param {*} nombre1 imagen tomboexpress en @const logoJuego1
   * @param {*} nombre2 imagen pataminibingo en @const logoJuego2
   * @param {*} nombre3 imagen patatelebingo en @const logoJuego3
   *
   */
  const cargarImgDinamica = (nombre0, nombre1, nombre2, nombre3, nombre4) => {
    import(`../img/${nombre0}_${codOrg}.png`).then((image) => {
      setlogoJuego0(image.default);
    });
    import(`../img/${nombre1}_${codOrg}.png`).then((image) => {
      setlogoJuego1(image.default);
    });
    import(`../img/${nombre2}_${codOrg}.png`).then((image) => {
      setlogoJuego2(image.default);
    });
    import(`../img/${nombre3}_${codOrg}.png`).then((image) => {
      setlogoJuego3(image.default);
    });
    import(`../img/${nombre4}_${codOrg}.png`).then((image) => {
      setlogoJuego4(image.default);
    });
  };

  /**
   * Acepta los terminos y condiciones haciendo llamado a una @function agreeTerm
   * la cual viene como prop
   */
  const handleAceptar = () => {
    props.agreeTerm();
  };

  /**
   * a partir de un @param {string} string, ls cual es una fecha y se lo trasnforma en @type Date
   * @returns un dato @type Date
   */
  const formatDate = (string) => {
    var options = { year: "numeric", month: "numeric", day: "numeric" };
    return new Date(string).toLocaleDateString(["es-ES"], options);
  };

  /**
   * Segun el codigo de juego que se pase por el @param {string} codigoJuego
   * se realiza una peticion fetch para traer los dato de los juegos.
   */
  const getDatosInicialesJuego = (codigoJuego) => {
    let parametros = {
      userToken: props.estadoPlataforma.userToken,
    };

    let url = "";

    if (
      (codigoJuego === "20" || codigoJuego === "21") &&
      (process.env.REACT_APP_ENABLE_PATA_MINIBINGO ||
        process.env.REACT_APP_ENABLE_PATA_TELEBINGO)
    ) {
      url =
        process.env.REACT_APP_WS_INIT_JUEGO_BINGO +
        "?codigoJuego=" +
        codigoJuego;
    } else if (codigoJuego === "6" && process.env.REACT_APP_ENABLE_POZO) {
      url =
        process.env.REACT_APP_WS_INIT_JUEGO_POZO +
        "?codigoJuego=" +
        codigoJuego;
    } else {
      url =
        process.env.REACT_APP_WS_INIT_JUEGO_QUINIELA +
        "?codigoJuego=" +
        codigoJuego;
    }

    fetch(url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json; charset=utf-8",
        Authorization: "Bearer " + parametros.userToken,
      },
    })
      .then((respPromise) => {
        if (respPromise.status === process.env.REACT_APP_CODIGO_CERRO_SESION) {
          props.cerrarSesion();
          props.history.push("/");
        }
        return respPromise.json();
      })
      .then((json) => {
        if (json.status === "ok") {
          if (codigoJuego === "0") {
            setcantCuponesJuego0(json.cuponesSinSortear);
          }

          if (codigoJuego === "20") {
            setcantCuponesJuego20(json.cuponesSinSortear);
          }

          if (codigoJuego === "21") {
            setcantCuponesJuego21(json.cuponesSinSortear);
            setjsonPromociones(json.promocionIconoJuego);
          }
          if (codigoJuego === "6") {
            setcantCuponesJuego6(json.cuponesSinSortear);
          }
        } else if (json.status === "error") {
        }
      })
      .catch((error) => {
        console.error(error.message);
      });
  };

  const clickIngresenCuil = () => {
    if (userCuil === null) {
      //this.irAPataMinibingo();placeholder="00-00000000-0"
      $("#cuilAltaCliente").mask("00-00000000-0", {
        placeholder: "00-00000000-0",
      });
      $.confirm({
        title:
          '<b className="op-text" id="tituloMiniBingoConfirm" >Actualice sus datos personales</b></br><b className="op-text" >Favor ingresar el CUIT/CUIL</b>',
        backgroundDismiss: true,
        columnClass: "medium",
        animation: "zoom",
        closeIcon: true,
        closeAnimation: "scale",
        content: `<div style="text-align: center;" ><input id="cuilAltaCliente" maxlength="13"  /></div> `,
        buttons: {
          No: {
            text: "Cancelar",
            action: function () {},
          },
          Si: {
            text: "Aceptar",
            action: function () {
              let cuil = $("#cuilAltaCliente").val();

              //irAPataMinibingo();
              altaCuilCliente(cuil, process.env.REACT_APP_COD_ORGANIZACION);
            },
          },
        },
        onContentReady: function () {
          $("#cuilAltaCliente").mask("00-00000000-0", {
            placeholder: "00-00000000-0",
          });
        },
      });
    } else {
    }
  };

  const altaCuilClienteIngreso = (aCuil, objThis, codigoOrganizacion) => {
    let parametros = {
      cuil: aCuil,
    };

    let url = process.env.REACT_APP_WS_CAMBIAR_DATOS_PERSONALES;

    let statusCode = "";

    let cerrarSesion = false;

    fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: "Bearer " + props.estadoPlataforma.userToken,
      },
      body: JSON.stringify(parametros),
    })
      .then((respPromise) => {
        statusCode = respPromise.status;
        if (respPromise.status == process.env.REACT_APP_CODIGO_CERRO_SESION) {
          cerrarSesion = true;
        }
        return respPromise.json();
      })
      .then((json) => {
        if (json.status === "ok") {
          if (codigoOrganizacion === process.env.REACT_APP_COD_ORGANIZACION) {
            this.irAPataMinibingo();
          } else {
            this.irAPataTeleBingo();
          }
        } else if (json.status === "error") {
          if (cerrarSesion) {
            props.mensajeErrorWS("CUIL inválido ", json.errores, cerrarSesion);
          } else {
            props.mensajeErrorWS("CUIL inválido ", json.errores);
          }
        }
      })
      .catch((error) => {
        props.mensajeErrorGeneral();
      });
  };

  /**
   * Verifica que el usuario detenga cuil con @const userCuil, en caso que el cuil sea null
   * se ejecuta un modal para setear el cuil del usuario. En caso que el usuario ya tenga cuil
   * se ejecuta la @function irAPataMinibingo
   */
  const clickPataMinibingo = () => {
    if (userCuil === null) {
      $("#cuilAltaCliente").mask("00-00000000-0", {
        placeholder: "00-00000000-0",
      });
      $.confirm({
        title:
          '<b className="op-text" id="tituloMiniBingoConfirm" >Actualice sus datos personales</b></br><b className="op-text" >Favor ingresar el CUIT/CUIL</b>',
        backgroundDismiss: true,
        columnClass: "medium",
        animation: "zoom",
        closeIcon: true,
        closeAnimation: "scale",
        content: `<div style="text-align: center;" ><input id="cuilAltaCliente" maxlength="13"  /></div> `,
        buttons: {
          No: {
            text: "Cancelar",
            action: function () {},
          },
          Si: {
            text: "Aceptar",
            action: function () {
              let cuil = $("#cuilAltaCliente").val();

              altaCuilCliente(cuil, "20");
            },
          },
        },
        onContentReady: function () {
          $("#cuilAltaCliente").mask("00-00000000-0", {
            placeholder: "00-00000000-0",
          });
        },
      });
    } else {
      setCargando(true);
      irAPataMinibingo();
    }
  };

  /**
   * Se ejecuta la @function actuVersionSiCorresponde, luego verifica que el usuario esté
   * logueado, en el caso que esta logueado, llama a la @function arrancarJuego, en caso contrario
   * nos redirige al login
   */
  const irAPataMinibingo = () => {
    props.actuVersionSiCorresponde(props);

    var str = process.env.REACT_APP_URL_JUEGO_PATA_MINIBINGO;
    if (str.slice(-1) === "/") str = str.slice(0, -1);

    if (props.estadoPlataforma.logueado) {
      props.arrancarJuego(process.env.REACT_APP_URL_JUEGO_PATA_MINIBINGO);
    } else {
      if (typeof props.requirioLoginJuego === "undefined") {
      } else {
        props.requirioLoginJuego(
          process.env.REACT_APP_URL_JUEGO_PATA_MINIBINGO
        );
        props.history.push("/login");
      }
    }
  };

  /**
   * Verifica que el usuario detenga cuil con @const userCuil, en caso que el cuil sea null 
   * se ejecuta un modal para setear el cuil del usuario. En caso que el usuario ya tenga cuil 
   * se ejecuta la @function irAPataTeleBingo

   */
  const clickPataTeleBingo = () => {
    let objThis = this;

    if (userCuil === null) {
      $("#cuilAltaCliente").mask("00-00000000-0", {
        placeholder: "00-00000000-0",
      });
      $.confirm({
        title:
          '<b className="op-text" id="tituloMiniBingoConfirm" >Favor ingrese su CUIL/CUIT</b>',
        backgroundDismiss: true,
        columnClass: "medium",
        animation: "zoom",
        closeIcon: true,
        closeAnimation: "scale",
        content: `<div style="text-align: center;" ><input id="cuilAltaClienteTele" maxlength="13"  /></div> `,
        buttons: {
          No: {
            text: "Cancelar",
            action: function () {},
          },
          Si: {
            text: "Aceptar",
            action: function () {
              let cuil = $("#cuilAltaClienteTele").val();

              altaCuilCliente(cuil, "21");
            },
          },
        },
        onContentReady: function () {
          $("#cuilAltaCliente").mask("00-00000000-0", {
            placeholder: "00-00000000-0",
          });
        },
      });
    } else {
      setCargando(true);
      irAPataTeleBingo();
    }
  };

  /**
   * Se ejecuta la @function actuVersionSiCorresponde, luego verifica que el usuario esté
   * logueado, en el caso que esta logueado, llama a la @function arrancarJuego, en caso contrario
   * nos redirige al login
   */
  const irAPataTeleBingo = () => {
    props.actuVersionSiCorresponde(props);

    var str = process.env.REACT_APP_URL_JUEGO_PATA_TELEBINGO;
    if (str.slice(-1) === "/") str = str.slice(0, -1);

    if (props.estadoPlataforma.logueado) {
      props.arrancarJuego(process.env.REACT_APP_URL_JUEGO_PATA_TELEBINGO);
    } else {
      if (typeof props.requirioLoginJuego === "undefined") {
      } else {
        props.requirioLoginJuego(
          process.env.REACT_APP_URL_JUEGO_PATA_TELEBINGO
        );
        props.history.push("/login");
      }
    }
  };

  const irAPozoQuiniela = () => {
    props.actuVersionSiCorresponde(props);

    var str = process.env.REACT_APP_URL_JUEGO_POZO;
    if (str.slice(-1) === "/") str = str.slice(0, -1);

    if (props.estadoPlataforma.logueado) {
      props.arrancarJuego(process.env.REACT_APP_URL_JUEGO_POZO);
    } else {
      if (typeof props.requirioLoginJuego === "undefined") {
      } else {
        props.requirioLoginJuego(process.env.REACT_APP_URL_JUEGO_POZO);
        props.history.push("/login");
      }
    }
  };

  /**
   * A partir de un @param {string} aCuil se realiza una peticion fetch donde se
   * actualiza el cuil del usuario, en caso que la peticion sea exitosa redirige al
   * juego correspondiente
   */
  const altaCuilCliente = (aCuil, codjuego) => {
    let parametros = {
      cuil: aCuil,
    };

    let url = process.env.REACT_APP_WS_CAMBIAR_DATOS_PERSONALES;

    let cerrarSesion = false;

    fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: "Bearer " + props.estadoPlataforma.userToken,
      },
      body: JSON.stringify(parametros),
    })
      .then((respPromise) => {
        if (respPromise.status === process.env.REACT_APP_CODIGO_CERRO_SESION) {
          cerrarSesion = true;
        }
        return respPromise.json();
      })
      .then((json) => {
        if (json.status === "ok") {
          traerMisDatosPersonales();
        } else if (json.status === "error") {
          if (cerrarSesion) {
            props.mensajeErrorWS("CUIL inválido ", json.errores, cerrarSesion);
          } else {
            props.mensajeErrorWS("CUIL inválido ", json.errores);
          }
        }
      })
      .catch((error) => {
        props.mensajeErrorGeneral();
      });
  };

  /**
   * Se ejecuta la @function actuVersionSiCorresponde, luego verifica que el usuario esté
   * logueado, en el caso que esta logueado, llama a la @function arrancarJuego, en caso contrario
   * nos redirige al login
   */
  const clickTombolaExpress = () => {
    setCargando(true);
    props.actuVersionSiCorresponde(props);

    var str = process.env.REACT_APP_URL_JUEGO_TOMBO_EXPRESS;
    if (str.slice(-1) === "/") str = str.slice(0, -1);

    if (props.estadoPlataforma.logueado) {
      props.arrancarJuego(process.env.REACT_APP_URL_JUEGO_TOMBO_EXPRESS);
    } else {
      if (typeof props.requirioLoginJuego === "undefined") {
      } else {
        props.requirioLoginJuego(process.env.REACT_APP_URL_JUEGO_TOMBO_EXPRESS);
        props.history.push("/login");
      }
    }
  };

  /**
   * Se ejecuta la @function actuVersionSiCorresponde, luego verifica que el usuario esté
   * logueado, en el caso que esta logueado, llama a la @function arrancarJuego, en caso contrario
   * nos redirige al login
   */
  const clickTombola = () => {
    setCargando(true);
    props.actuVersionSiCorresponde(props);

    var str = process.env.REACT_APP_URL_JUEGO_TOMBOLA;
    if (str.slice(-1) === "/") str = str.slice(0, -1);

    if (props.estadoPlataforma.logueado) {
      props.arrancarJuego(process.env.REACT_APP_URL_JUEGO_TOMBOLA);
    } else {
      if (typeof props.requirioLoginJuego === "undefined") {
      } else {
        props.requirioLoginJuego(process.env.REACT_APP_URL_JUEGO_TOMBOLA);
        props.history.push("/login");
      }
    }
  };

  const clickPozoQuiniela = () => {
    let objThis = this;

    if (userCuil === null) {
      $("#cuilAltaCliente").mask("00-00000000-0", {
        placeholder: "00-00000000-0",
      });
      $.confirm({
        title:
          '<b className="op-text" id="tituloPozoConfirm" >Favor ingrese su CUIL/CUIT</b>',
        backgroundDismiss: true,
        columnClass: "medium",
        animation: "zoom",
        closeIcon: true,
        closeAnimation: "scale",
        content: `<div style="text-align: center;" ><input id="cuilAltaClientePozo" maxlength="13"  /></div> `,
        buttons: {
          No: {
            text: "Cancelar",
            action: function () {},
          },
          Si: {
            text: "Aceptar",
            action: function () {
              let cuil = $("#cuilAltaClientePozo").val();

              altaCuilCliente(cuil, "6");
            },
          },
        },
        onContentReady: function () {
          $("#cuilAltaCliente").mask("00-00000000-0", {
            placeholder: "00-00000000-0",
          });
        },
      });
    } else {
      setCargando(true);
      console.log("Estoy Aca");
      irAPozoQuiniela();
    }
  };

  return (
    <div>
      {cargando ? (
        <DivCargando className="container">
          <EfectoCargando />
        </DivCargando>
      ) : (
        <ContainerJuegos size={size} className="container">
          <div className="row justify-content-center">
            <div className="col-12">
              {(props.estadoPlataforma.autoexcluidoHasta === undefined ||
                props.estadoPlataforma.autoexcluidoHasta === null) && (
                <H5Titulo className="text-center">
                  ¿Qué querés jugar hoy?
                </H5Titulo>
              )}
            </div>
          </div>
          <Rowjuegos className="row justify-content-start">
            <LlamadorJuego
              size={size}
              enableJuego={process.env.REACT_APP_ENABLE_TOMBOLA}
              clickJuego={clickTombolaExpress}
              cantCuponesJuego={cantCuponesJuego0}
              logoJuego={logoJuego0}
              nombreJuego={
                ["13", "43", "11", "41", "42", "12"].includes(
                  process.env.REACT_APP_COD_ORGANIZACION
                )
                  ? "Quiniela tradicional"
                  : "Tómbola"
              }
              urljuego={
                ["13", "43", "11", "41", "42", "12"].includes(
                  process.env.REACT_APP_COD_ORGANIZACION
                )
                  ? "Quinielatradicional"
                  : "Tombola"
              }
              estadoPlataforma={props.estadoPlataforma}
            />

            <LlamadorJuego
              size={size}
              enableJuego={process.env.REACT_APP_ENABLE_TOMBO_EXPRESS}
              clickJuego={clickTombola}
              logoJuego={logoJuego1}
              nombreJuego={
                ["13", "43", "11", "41"].includes(
                  process.env.REACT_APP_COD_ORGANIZACION
                )
                  ? "Quini Express"
                  : ["53", "23"].includes(
                      process.env.REACT_APP_COD_ORGANIZACION
                    )
                  ? "Tombo Express"
                  : "Quiniela Instantánea"
              }
              urljuego={
                ["13", "43", "11", "41"].includes(
                  process.env.REACT_APP_COD_ORGANIZACION
                )
                  ? "QuiniExpress"
                  : ["53", "23"].includes(
                      process.env.REACT_APP_COD_ORGANIZACION
                    )
                  ? "TomboExpress"
                  : "quinielainstantanea"
              }
              estadoPlataforma={props.estadoPlataforma}
            />

            <LlamadorJuego
              size={size}
              enableJuego={process.env.REACT_APP_ENABLE_PATA_MINIBINGO}
              clickJuego={clickPataMinibingo}
              cantCuponesJuego={cantCuponesJuego20}
              logoJuego={logoJuego2}
              nombreJuego={"Patagonia Minibingo"}
              urljuego={"patagoniaminibingo"}
              estadoPlataforma={props.estadoPlataforma}
              tieneCuil={userCuil}
            />

            <LlamadorJuego
              size={size}
              enableJuego={process.env.REACT_APP_ENABLE_PATA_TELEBINGO}
              clickJuego={clickPataTeleBingo}
              cantCuponesJuego={cantCuponesJuego21}
              logoJuego={logoJuego3}
              nombreJuego={"Patagonia Telebingo"}
              urljuego={"patagoniatelebingo"}
              promocion={jsonPromociones}
              estadoPlataforma={props.estadoPlataforma}
              tieneCuil={userCuil}
            />
            <LlamadorJuego
              size={size}
              enableJuego={process.env.REACT_APP_ENABLE_POZO}
              clickJuego={clickPozoQuiniela}
              cantCuponesJuego={cantCuponesJuego6}
              logoJuego={logoJuego4}
              nombreJuego={"Pozo de la Quiniela"}
              urljuego={"pozodelaquiniela"}
              estadoPlataforma={props.estadoPlataforma}
              tieneCuil={userCuil}
            />
          </Rowjuegos>
          <div className="row justify-content-center">
            <div className={size > 500 ? "col-6" : "col-12"}>
              <Animated
                animationIn="fadeIn"
                animationInDelay={500}
                className="mx-auto"
              >
                <CarrouselPublicidad />
              </Animated>
            </div>
          </div>
          {props.estadoPlataforma.autoexcluidoHasta !== undefined && (
            <Animated
              animationIn="flipInY"
              animationInDuration={1200}
              isVisible={true}
            >
              <div className="bg-light w-75 mx-auto rounded shadow p-3 pt-4 pb-4 text-center">
                <h4>
                  {["13", "43"].includes(process.env.REACT_APP_COD_ORGANIZACION)
                    ? "autolimitado"
                    : "autoexcluido"}{" "}
                  hasta el <br />
                  <b>{formatDate(props.estadoPlataforma.autoexcluidoHasta)}</b>
                </h4>
              </div>
            </Animated>
          )}
        </ContainerJuegos>
      )}

      <div
        className="modal fade"
        id="tycModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="tycModalLabel"
        aria-hidden="true"
      >
        <div
          className="modal-dialog"
          style={{ marginTop: "5rem" }}
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="tycModalLabel">
                Terminos y Condiciones
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body" style={{ height: "60vh" }}>
              {!props.estadoPlataforma.fetching &&
                props.estadoPlataforma !== null &&
                props.estadoPlataforma.tyc !== null &&
                ReactHtmlParser(props.estadoPlataforma.tyc.tyc)}
            </div>
            <div className="modal-footer">
              <button className="btn btn-info mx-auto" onClick={handleAceptar}>
                Acepto Terminos y Condiciones
              </button>
            </div>
          </div>
        </div>
      </div>

      {props.estadoPlataforma.nps !== undefined &&
        props.estadoPlataforma.nps !== null && (
          <Calificar estadoPlataforma={props.estadoPlataforma} />
        )}

      {props.estadoPlataforma.estado === "PLATAFORMA" && (
        <ModalAvisosGenerales
          showModal={avisosGenerales}
          logoTombola={logoJuego0}
          logoTomboexpress={logoJuego1}
          logoPatagoniaMiniBingo={logoJuego2}
          logoPatagoniaTelebingo={logoJuego3}
          clickPataMinibingo={clickPataMinibingo}
          clickPataTeleBingo={clickPataTeleBingo}
          clickTombolaExpress={clickTombolaExpress}
          clickTombola={clickTombola}
        />
      )}
    </div>
  );
};

export default withRouter(Home);

const ContainerJuegos = styled.div`
  min-width: 15vh;
  margin-top: 3.5rem;
`;

export const Rowjuegos = styled.div`
  flex-wrap: nowrap;
  overflow: auto;
  padding-left: 4vw;
`;

const H5Titulo = styled.h5`
  font-weight: 700;
  font-family: Mont-Bold !important;
`;
